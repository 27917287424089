import React from 'react';
import "./About.css";
export default function About() {
   return (
      <section className="contact-section">
         <div className="container">
            <div className="row justify-content-center">
               <div className="col-md-10">
                  <div className="wrappers">
                     <div className="row no-gutters">
                        <div className="col-md-6">
                           <div className="contact-wrap w-100 p-lg-5 p-4">
                              <h3>Who We Are</h3>
                              <p>Abeer Alamal is the name of hope and hope is there where is believe, believe comes once you trust.</p>
                              <p>Abeer Alamal Since 2016, Located in Dubai. Abeer Alamal Technical Works. At Home-Designing, we document inspiration. Our aim is to bring to our readers a steady set of resources that would help them visualize, create and maintain beautiful homes. Our emphasis is on visuals that motivate you. We cover architectural innovations, cool homes, ideas for specific rooms, new design trends, products, and occasionally decor tips. We hope to become your one-stop source for home design inspiration!</p>
                              <h5>Here are a Few Highlights:</h5>
                              <ul >
                                 <li>Design & Decor</li>
                                 <li>Concept & Mananagement</li>
                                 <li>Planning & Execution</li>
                                 <li>Civil and Mep</li>
                                 <li>Glass and Aluminium</li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-stretch">
                           <div className="info-wrap w-100 p-lg-5 p-4">
                              <div className="pic">
                                 <figure><img className='q' src="logo2.png" style={{ width: "100%" }} alt="logo GB.jpeg" /></figure>
                                 <figure><img className='q' src="04.jpg" style={{ width: "100%" }} alt="logo GB.jpeg" /></figure>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

   )
}
