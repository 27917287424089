import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./Contact.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {
   const form = useRef();

   const sendEmail = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_sa7h9om', 'template_4f65o0i', form.current, 'W5cniWB5Lh6HZSCW6')
         .then((result) => {
            console.log(result.text);
         }, (error) => {
            console.log(error.text);
         });
      e.target.reset()
   };

   const notify = () => toast.success('Message Send successfully', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    });

   return (
    <>
    <section className="contact-section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="wrapper">
            <div className="row no-gutters">
              <div className="col-md-6">
                <div className="contact-wrap w-100 p-lg-5 p-4">
                  <h3 className="mb-4">Send us a message</h3>
                  <form id="contactForm" className="contactForm" ref={form} onSubmit={sendEmail}>

                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <input type="text" className="form-control" name="user_name" placeholder="Name" />
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <input type="email" className="form-control" name="user_email" placeholder="Email" />
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <input type="text" className="form-control" name="number"  placeholder="Phone" />
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <textarea type="text" className="form-control" name="message" placeholder="Project Description" cols="30" rows="6" ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <input  type="submit" onClick={notify}  value="Send Message" className="btn btn-primary" />
                          <ToastContainer />
                        </div>
                      </div> 
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch">
                <div className="info-wrap w-100 p-lg-5 p-5 img">
                  <h3>Get a quote</h3>
                  <p className="mb-4">Abeer Alamal is the name of hope and hope is there where is believe, believe comes once you trust.</p>

                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="text pl-3">
                      <p><span><b>Address:</b></span> RAG business center Ground floor Shekha Mehra building, Qusais Dubai.</p>
                    </div>
                  </div>

                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="text pl-3">
                      <p><span><b>Phone:</b></span><a href="tel://+971503025272">   +971 50 302 5272</a></p>
                    </div>
                  </div>

                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="text pl-3">
                      <p><span><b>Email:</b></span><a href="mailto:hr.abhope@gmail.com">  Contact@abeeralamal.com</a></p>
                    </div>
                  </div>

                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="text pl-3">
                      <p><span><b>Office Time:</b></span>  9:00 AM To 6:00 PM</p>
                    </div>
                  </div>

                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="text pl-3">     
                    <p><span><b>Working Days:</b></span>  Saturday To Thursday</p>
                    <p><span><b>Holiday:</b></span>  Friday OFF</p>
                  </div>
                  
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
      <div className="map">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe className="gmap_iframe" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=AlQusais3Dubai&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"  ></iframe>
          </div>
        </div>
      </div>
  
  </>
  
   )
}
export default Contact;