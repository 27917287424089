import React from 'react';
import CarouselContainer from '../CarouselContainer/CarouselContainer';


const Home = () => {
  
  return (
    
    <div>
      <CarouselContainer/>
    </div>
  )
}

export default Home;
