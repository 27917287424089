import "./footer.css";
import { faFacebook, faInstagram, faTiktok, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function(){
    return(
  
  <>
  {/* Footer */}
  <footer className="text-center text-lg-start bg-light text-muted">
    {/* Section: Social media */}
    <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      {/* Left */}
      <div className="me-5 d-none d-lg-block">
        <span>Get Connected with us on Social Networks:</span>
      </div>
      {/* Left */}
      {/* Right */}
      <div>
        <a href="https://www.facebook.com/abeerever" className="me-4 text-reset">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="/" className="me-4 text-reset">
        <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.tiktok.com/@abeerarzo?_t=8ZM6dql2Rd4&_r=1" className="me-4 text-reset">
        <FontAwesomeIcon icon={faTiktok} />
        </a>
        <a href="/" className="me-4 text-reset">
        <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a href="https://wa.me/+971503025272" className="me-4 text-reset">
        <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
      {/* Right */}
    </section>
    {/* Section: Social media */}

    {/* Section: Links  */}
    <section className="">
      <div className="container text-center text-md-start mt-5">
        {/* Grid row */}
        <div className="row mt-3">
          {/* Grid column */}
          <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            {/* Content */}
            <h6 className="text-uppercase fw-bold mb-4">
              {/* <i className="fas fa-gem me-3" /> */}
              Abeer Alamal
            </h6>
            <p> Abeer Alamal is the name of hope and hope is there where is believe, believe comes once you trust. </p>
          </div>
          {/* Grid column */}
          
         
          {/* Grid column */}
          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            {/* Links */}
            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
            <p>
            {/* <i className="fas fa-home me-3" /> */}
              RAG business center Ground floor Shekha Mehra building, Qusais Dubai.
            </p>
            <p>
              {/* <i className="fas fa-envelope me-3" /> */}
              Contact@abeeralamal.com
            </p>
            <p>
              {/* <i className="fas fa-phone me-3" />  */}
              +971 50 302 5272
            </p>
          </div>
          {/* Grid column */}
        </div>
        {/* Grid row */}
      </div>
    </section>
    {/* Section: Links  */}
    {/* Copyright */}
    <div
      className="text-center p-4"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
    >
      {/* © 2021 Copyright:
      <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
        MDBootstrap.com
      </a> */}
    </div>
    {/* Copyright */}
  </footer>
  {/* Footer */}
</>


    )
}
