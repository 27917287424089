import React, { useState } from 'react';
import "./Customizefurniture.css";
import CloseIcon from '@mui/icons-material/Close';
import Img1 from '../../Customizefurniture/01.jpg';
import Img2 from '../../Customizefurniture/02.jpeg';
import Img3 from '../../Customizefurniture/03.jpeg';
import Img4 from '../../Customizefurniture/04.jpeg';
import Img5 from '../../Customizefurniture/05.jpeg';
import Img6 from '../../Customizefurniture/06.jpeg';
import Img7 from '../../Customizefurniture/07.jpeg';
import Img8 from '../../Customizefurniture/08.jpeg';
import Img9 from '../../Customizefurniture/09.jpeg';
import Img10 from '../../Customizefurniture/10.jpeg';
import Img11 from '../../Customizefurniture/11.jpeg';
import Img12 from '../../Customizefurniture/12.jpeg';
import Img13 from '../../Customizefurniture/13.jpeg';
import Img14 from '../../Customizefurniture/14.jpeg';
import Img15 from '../../Customizefurniture/15.jpeg';
import Img16 from '../../Customizefurniture/16.jpeg';
import Img17 from '../../Customizefurniture/17.jpeg';
import Img18 from '../../Customizefurniture/18.jpeg';
import Img19 from '../../Customizefurniture/19.jpeg';
import Img20 from '../../Customizefurniture/20.jpeg';
import Img21 from '../../Customizefurniture/21.jpeg';
import Img22 from '../../Customizefurniture/22.jpeg';
import Img23 from '../../Customizefurniture/23.jpeg';
import Img24 from '../../Customizefurniture/24.jpeg';
import Img25 from '../../Customizefurniture/25.jpeg';
import Img26 from '../../Customizefurniture/26.jpeg';
import Img27 from '../../Customizefurniture/27.jpeg';
import Img28 from '../../Customizefurniture/28.jpeg';
import Img29 from '../../Customizefurniture/29.jpeg';
import Img30 from '../../Customizefurniture/30.jpeg';
import Img31 from '../../Customizefurniture/31.jpeg';
import Img32 from '../../Customizefurniture/32.jpeg';
import Img33 from '../../Customizefurniture/33.jpeg';
import Img34 from '../../Customizefurniture/34.jpeg';
import Img35 from '../../Customizefurniture/35.jpeg';
import Img36 from '../../Customizefurniture/36.jpeg';
import Img37 from '../../Customizefurniture/37.jpeg';
import Img38 from '../../Customizefurniture/38.jpeg';
import Img39 from '../../Customizefurniture/39.jpeg';
import Img40 from '../../Customizefurniture/40.jpeg';
import Img41 from '../../Customizefurniture/41.jpeg';
import Img42 from '../../Customizefurniture/42.jpeg';
import Img43 from '../../Customizefurniture/43.jpeg';
import Img44 from '../../Customizefurniture/44.jpeg';
import Img45 from '../../Customizefurniture/45.jpeg';
import Img46 from '../../Customizefurniture/46.jpeg';
import Img47 from '../../Customizefurniture/47.jpeg';
import Img48 from '../../Customizefurniture/48.jpeg';
import Img49 from '../../Customizefurniture/49.jpeg';
import Img50 from '../../Customizefurniture/50.jpeg';
import Img51 from '../../Customizefurniture/51.jpeg';
import Img52 from '../../Customizefurniture/52.jpeg';
import Img53 from '../../Customizefurniture/53.jpeg';
import Img54 from '../../Customizefurniture/54.jpeg';
import Img55 from '../../Customizefurniture/55.jpeg';
import Img56 from '../../Customizefurniture/56.jpeg';
import Img57 from '../../Customizefurniture/57.jpeg';
import Img58 from '../../Customizefurniture/58.jpeg';
import Img59 from '../../Customizefurniture/59.jpeg';



const ReadyMade = () => {
  let image = [
    {
      id: 1,
      imgSrc: Img1,
    },
    {
      id: 2,
      imgSrc: Img2,
    },
    {
      id: 3,
      imgSrc: Img3,
    },
    {
      id: 4,
      imgSrc: Img4,
    },
    {
      id: 5,
      imgSrc: Img5,
    },
    {
      id: 6,
      imgSrc: Img6,
    },
    {
      id: 7,
      imgSrc: Img7,
    },
    {
      id: 8,
      imgSrc: Img8,
    },
    {
      id: 9,
      imgSrc: Img9,
    },
    {
      id: 10,
      imgSrc: Img10,
    },
    {
      id: 11,
      imgSrc: Img11,
    },
    {
      id: 12,
      imgSrc: Img12,
    }, 
    {
      id: 13,
      imgSrc: Img13,
    },
    {
      id: 14,
      imgSrc: Img14,
    },
    {
      id: 15,
      imgSrc: Img15,
    },
    {
      id: 16,
      imgSrc: Img16,
    },
    {
      id: 17,
      imgSrc: Img17,
    },
    {
      id: 18,
      imgSrc: Img18,
    },
    {
      id: 19,
      imgSrc: Img19,
    },
    {
      id: 20,
      imgSrc: Img20,
    },
    {
      id: 21,
      imgSrc: Img21,
    },
    {
      id: 22,
      imgSrc: Img22,
    },
    {
      id: 23,
      imgSrc: Img23,
    },
    {
      id: 24,
      imgSrc: Img24,
    },
    {
      id: 25,
      imgSrc: Img25,
    },
    {
      id: 26,
      imgSrc: Img26,
    },
    {
      id: 27,
      imgSrc: Img27,
    },
    {
      id: 28,
      imgSrc: Img28,
    },
    {
      id: 29,
      imgSrc: Img29,
    },
    {
      id: 30,
      imgSrc: Img30,
    },
    {
      id: 31,
      imgSrc: Img31,
    },
    {
      id: 32,
      imgSrc: Img32,
    }, 
    {
      id: 33,
      imgSrc: Img33,
    },
    {
      id: 34,
      imgSrc: Img34,
    },
    {
      id: 35,
      imgSrc: Img35,
    },
    {
      id: 36,
      imgSrc: Img36,
    },
    {
      id: 37,
      imgSrc: Img37,
    },
    {
      id: 38,
      imgSrc: Img38,
    },
    {
      id: 39,
      imgSrc: Img39,
    },
    {
      id: 40,
      imgSrc: Img40,
    },
    {
      id: 41,
      imgSrc: Img41,
    },
    {
      id: 42,
      imgSrc: Img42,
    },
    {
      id: 43,
      imgSrc: Img43,
    },
    {
      id: 44,
      imgSrc: Img44,
    },
    {
      id: 45,
      imgSrc: Img45,
    },
    {
      id: 46,
      imgSrc: Img46,
    },
    {
      id: 47,
      imgSrc: Img47,
    },
    {
      id: 48,
      imgSrc: Img48,
    },
    {
      id: 49,
      imgSrc: Img49,
    },
    {
      id: 50,
      imgSrc: Img50,
    },
    {
      id: 51,
      imgSrc: Img51,
    },
    {
      id: 52,
      imgSrc: Img52,
    }, 
    {
      id: 53,
      imgSrc: Img53,
    },
    {
      id: 54,
      imgSrc: Img54,
    },
    {
      id: 55,
      imgSrc: Img55,
    },
    {
      id: 56,
      imgSrc: Img56,
    },
    {
      id: 57,
      imgSrc: Img57,
    },
    {
      id: 58,
      imgSrc: Img58,
    },
    {
      id: 59,
      imgSrc: Img59,
    },
    

  ]
 
const [model, setModel] = useState(false);
const [tempimgSrc, setTemImgSrc] = useState('');  
const getImg=(imgSrc)=>{
  setTemImgSrc(imgSrc);
  setModel(true);
   
}

  return (
    <>
      <h2>Customize Furniture</h2>
      <div className={model? "model open": "model"}>
        <img src={tempimgSrc} alt=""/>
        <CloseIcon onClick={()=> setModel(false)}/>
      </div>

      <div className='gallery'>
          {image.map((item, index)=>{
            return(
              <div className='pics' key={index} onClick={()=> getImg(item.imgSrc)}>
                <img src={item.imgSrc} style={{width: '100%'}} alt=""/>
              </div>
            )
          })}
       </div>

    </>

  )

}
export default ReadyMade;
