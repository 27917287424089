import React from 'react'
import "./Navbar.css"
import { Link } from 'react-router-dom'

export default function Navbar() {
    return (
        
        <nav className="navbar navbar-expand-lg navbar-dark mriclass">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/"><img src='logo2.png'style={{width: 200, height:50}} alt="Abeer Alamal logo" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/About">About us</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/Services">Services</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/Projects">Projects</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/Customizefurniture">Customize furniture</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/clients">Our Clients</Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link" to="/Contact">Contact</Link>
                        </li>

                        
                    </ul>
                </div>
            </div>
        </nav>
    )
}
